import { memo, useCallback } from 'react'
import { setSelectedVehicle, getSelectedVIN, clearSelectedVIN } from 'store/slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getVehicleIdentifier } from 'helper/vehicle'
import VehicleAlertCard from './VehicleAlertCard'
import { getRelativeTime } from 'helper/time'
import { useClock } from 'hooks/useClock'
import { NO_DATA } from 'helper/constants'
import { AlertWithVIN } from 'helper/types'
import useSelectedVehicleSubscription from 'hooks/useSelectedVehicleSubscription'

export interface AlertWithRelativeTime extends AlertWithVIN {
  relativeTime: string
}

interface AlertComponentProps {
  alert: AlertWithVIN
  className?: string
}

const VehicleAlert = ({ alert, className }: AlertComponentProps) => {
  const dispatch = useDispatch()
  const selectedVin = useSelector(getSelectedVIN)

  const datetime = useClock()

  const { data: vehicle } = useSelectedVehicleSubscription({ vin: alert.vin })

  const isSelected = selectedVin === vehicle?.vin
  const vehicleIdentifier = vehicle ? getVehicleIdentifier(vehicle) : NO_DATA

  const alertWithRelativeTime: AlertWithRelativeTime = {
    ...alert,
    relativeTime: getRelativeTime(datetime, new Date(alert.sentAt)) // test pw
  }

  const handleClick = useCallback(() => {
    dispatch(
      isSelected
        ? clearSelectedVIN()
        : setSelectedVehicle({ selectedVIN: vehicle?.vin, followSelectedVehicle: true })
    )
  }, [dispatch, isSelected, vehicle?.vin])

  return (
    <>
      <VehicleAlertCard
        data={alertWithRelativeTime}
        onClick={handleClick}
        vehicleIdentifier={vehicleIdentifier}
        isSelected={isSelected}
        className={className}
        mrmState={vehicle?.mrmState}
      />
    </>
  )
}

export default memo(VehicleAlert)
